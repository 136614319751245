import React, { useState,useEffect } from 'react';

import Wrapper from "../../hooks/wrapper";
import Functions from "../../hooks/functions";
import 'select2/dist/css/select2.min.css';
import 'select2/dist/js/select2.min.js';
import * as $ from "jquery";
window.Jquery = window.$ = $
var altoE = 0
var alto = 0
var antes = ''
const PopFactura = (props)=>{
    const [cfdi, setCfdi] = useState({});
    const [rfiscal, setRFiscal] = useState({});

    const [estados, setEstados] = useState([]);
    const [municipios, setMunicipios] = useState({});
    const [colonias, setColonias] = useState([]);

    const [nombre, setNombre] = useState('');
    const [rfc, setRFC] = useState('');
    const [scfdi, setSCfdi] = useState("");
    const [srfiscal, setSRFiscal] = useState("");
    const [sEstado, setSEstado] = useState('');
    const [sMunicipio, setSMunicipio] = useState('');
    const [sColonia, setSColonia] = useState('');
    const [calle, setCalle] = useState('');
    const [exterior, setExterior] = useState('');
    const [interior, setInterior] = useState('');
    const [contador, setContador] = useState(0);
    const [copia, setCopia] = useState('');
    const [cp, setCP] = useState('');
    // const [copiai, setCopiai] = useState('0');

    useEffect (() => {
        // console.log("carga de factura");
        // console.log(props);
        getEstados()
    },[])
    $(document).ready(function() {
        $('.js-example-basic-single').select2();
        $('.js-example-basic-single').change(prueba);
    });
    function rellenar() {
        // console.log(props);
        setNombre(props?.data?.RequesterName)
        setRFC(props?.data?.RequesterRFC)
        setSCfdi(props?.data?.CFDIUSE)
        setSRFiscal(props?.data?.RFiscal)
        setCP(props?.data?.RequesterAddress?.ZipCode)
//        setSEstado(props?.data?.RequesterAddress?.StateID)
//        getMunicipios(props?.data?.RequesterAddress?.StateID)
//        setSMunicipio(props?.data?.RequesterAddress?.CityID)
//        getColonias(props?.data?.RequesterAddress?.CityID,props?.data?.RequesterAddress?.StateID)
//        setSColonia(props?.data?.RequesterAddress?.NeighborhoodID)
//        setCalle(props?.data?.RequesterAddress?.StreetName)
//        setExterior(props?.data?.RequesterAddress?.StreetExtNumber)
//        setInterior(props?.data?.RequesterAddress?.StreetIntNumber)
    }
    async function getEstados() {
        const edo = await Wrapper.getDataU('states')
        const cf = await Wrapper.getDataU('catalogs/cfdi_uses')

        const rf = getRFiscales();

        // console.log(edo);
        // console.log(cf);
        // console.error(rf);
        setEstados(edo.data.data.states)
        setCfdi(cf.data.data)
        setRFiscal(rf)
    }
    async function getMunicipios(event) {
        // console.log('event Muicipios');
        // console.log(event);
        let idEdo = ''
        if (event?.target !== undefined) {
            idEdo = event?.target?.value
        }else{
            idEdo = event
        }
        setSEstado(idEdo)
        const cds = await Wrapper.getDataU('state/'+idEdo+'/cities')
        setMunicipios(cds?.data?.data?.cities)
    }
    async function getColonias(event,stado) {
        // console.log(event);
        // console.log(stado);
        let idmuni = ''
        let estado = ''
        if (event?.target !== undefined) {
            idmuni = event?.target?.value
            estado = sEstado
        }else{
            idmuni = event
            estado = stado
        }
        // console.log(idmuni);
        // console.log(estado);
        setSMunicipio(idmuni)
        const colo = await Wrapper.getDataU('state/'+estado+'/city/'+idmuni+'/neighborhoods')
        // console.log(colo);
        if(colo !== undefined){
            setColonias(colo.data.data.neighborhoods)
        }
    }
    function sendMunicipios(event) {
        setSColonia(event.target.value)
    }
    async function addState(event) {
        // console.log('event.target.name');
        // console.log(event);
        switch (event.target.name) {
            case 'cp':
                let estados = document.querySelector('#sEstado')
                setCP(event.target.value)
                break;
            case 'nombre':
                setNombre(event.target.value)
                break;
            case 'rfc':
                setRFC(event.target.value)
                return Functions.FormatRFC('rfc')
            case 'scfdi':
                setSCfdi(event.target.value)
                break;
            case 'srfiscal':
                setSRFiscal(event.target.value)
                break;
            case 'calle':
                setCalle(event.target.value)
                break;
            case 'exterior':
                setExterior(event.target.value)
                break;
            case 'interior':
                setInterior(event.target.value)
                break;
            default:
                break;
        }
    }
    function save(event) {
        // console.log('save');
        event.preventDefault()
        if (scfdi !== undefined)
        {
            let BillingInfo = {}
            let RequesterAddress = {}
            BillingInfo.InvoiceRequired = true
            BillingInfo.RequesterRFC = rfc
            BillingInfo.RequesterName = nombre
            BillingInfo.CFDIUSE = scfdi
            BillingInfo.RFiscal = srfiscal
            BillingInfo.RequesterAddress = RequesterAddress
            RequesterAddress.StreetName = calle
            RequesterAddress.StreetIntNumber = interior
            RequesterAddress.StreetExtNumber = exterior
            RequesterAddress.NeighborhoodID = sColonia
            RequesterAddress.CityID = sMunicipio
            RequesterAddress.StateID = sEstado
            RequesterAddress.ZipCode = cp
            // console.log(BillingInfo);
            if (props.getDataFacture !== undefined) {
                props.getDataFacture(BillingInfo,'add')
            }
        }else{
            setSCfdi("")
            let scfdi = document.querySelector("#scfdi")
            scfdi.focus()

            setSRFiscal("")
            let srfiscal = document.querySelector("#srfiscal")
            srfiscal.focus()
        }
    }
    function cacelar() {
        props.setActiveFeature(false)
        props.setActiveModal(false)
    }
    function prueba(event){
        let cambio = document.querySelector('#select2-'+event.target.name+'-container').innerHTML
        switch (event.target.name) {
            case 'sEstado':
                if (cambio !== 'Selecciona tu estado') {
                    setSEstado(event.target.value)
                    setCopia(cambio)
                    setSMunicipio('')
                    setSColonia('')
                    // getMunicipios(event.target.value)
                    if(altoE === 0){
                        altoE = 1
                        // console.log('altoe');
                        getMunicipios(event.target.value)
                    }
                    if (copia !== '') {
                        if (cambio !== copia && antes !== cambio) {
                            antes = cambio
                            getMunicipios(event.target.value)
                        }
                    }

                }
                break;
            case 'sMunicipio':
                if (cambio !== 'Selecciona tu municipio o delegacion') {
                    setSMunicipio(event.target.value)
                    setCopia(cambio)
                    setSColonia('')
                    if(copia === '' && event.target.value !== '' && sEstado !== '' && alto === 0){
                        alto = 1
                        getColonias(event.target.value,sEstado)
                    }
                    if (copia !== '') {
                        if (cambio !== copia && antes !== cambio) {
                            if (event.target.value !== '' && sEstado !== '') {
                                antes = cambio
                                getColonias(event.target.value,sEstado)
                            }
                        }
                    }
                }
                break;
            case 'sColonia':
                if (cambio !== 'Selecciona tu colonia') {
                    setSColonia(event.target.value)
                }
                break;
            default:
                break;
        }
    }
    if (Object.keys(cfdi).length > 0 && Object.keys(estados).length > 0) {
    // if (Object.keys(estados).length > 0) {
        if (props?.data !== undefined && contador === 0) {
            rellenar()
            setContador(1)
        }
        return(
            <div className="pop centrar">
                <div className="card w-70 m-auto pop-card">
                    <div className="card-title bg-orange white-text text-white">
                        <div className="row sm">
                            <div className="col s10 l10">
                                <span className="text-center w-100 fz-20">Información de Facturación</span>
                            </div>
                            <div className="col s2 l2 text-right">
                                <div className="close centrar" onClick={cacelar}>
                                    <i className="material-icons-outlined text-white fz-40 ml-auto">highlight_off</i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form className="w-100 row p-20" onSubmit={save}>
                        <div className="col s12 l6">
                            <label>
                                <span>Razón Social</span>
                                <input type="text" name="nombre" placeholder="Razón Social" required defaultValue={nombre} onChange={(e)=>addState(e)} />
                            </label>
                        </div>
                        <div className="col s12 l6">
                            <label>
                                <span>RFC</span>
                                <input type="text" name="rfc" placeholder="RFC" required maxLength='13' id="rfc" onChange={(e)=>addState(e)} defaultValue={rfc}/>
                            </label>
                        </div>
                        <div className="col s12 l6">
                            <label>
                                <span>Uso de CFDI</span>
                                <select defaultValue={scfdi} required onChange={(e)=>addState(e)} name="scfdi" id="scfdi" >
                                    <option  value="" >Selecciona el CFDI</option>
                                    {Object.keys(cfdi).map((e,index)=>{
                                        return(
                                            <option key={"cfdi"+index} value={cfdi[e].id}>{cfdi[e].description}</option>
                                        )
                                    })}
                                </select>
                            </label>
                        </div>

                        <div className="col s12 l6">
                            <label>
                                <span>Régimen Fiscal</span>
                                <select defaultValue={srfiscal} required onChange={(e)=>addState(e)} name="srfiscal" id="srfiscal" >
                                    <option  value="" >Selecciona el Régimen Fiscal</option>
                                    {Object.keys(rfiscal).map((e,index)=>{
                                        return(
                                            <option key={"rfiscal"+index} value={rfiscal[e].id}>{rfiscal[e].description}</option>
                                        )
                                    })}
                                </select>
                            </label>
                        </div>



                        <div className="col s12 l6">
                            <label>
                                <span>Código Postal</span>
                                <input type="text" name="cp" placeholder="Codigo Postal" id="cp" onChange={(e)=>addState(e)} defaultValue={cp} maxLength='5' minLength='5' onKeyPress={()=>Functions.soloNumeros()}/>
                            </label>
                        </div>

                        <div className="col s12 l6">
                        </div>


                        <div className="btn-pop">
                        <button className="btn bg-orange">Aceptar</button>
                        <button className="btn-flat ml-5 orange-text waves-effect waves-orange" onClick={cacelar}>Cancelar</button>
                        </div>   
                        
                    </form>
                </div>
            </div>
        )
    }else{
        return(
            <div></div>
        )
    }

    function getRFiscales()
    {
        const rfiscales =
            {
                601:
                    {
                        id: '601',
                        description: 'General de Ley Personas Morales',
                        fisica: false, //no
                        moral: true // si
                    },
                603:
                    {
                        id: '603',
                        description: 'Personas Morales con Fines no Lucrativos',
                        fisica: false, //no
                        moral: true // si
                    },
                605:
                    {
                        id: '605',
                        description: 'Sueldos y Salarios e Ingresos Asimilados a Salarios',
                        fisica: true, //si
                        moral: false //no
                    },
                606:
                    {
                        id: '606',
                        description: 'Arrendamiento',
                        fisica: true, //si
                        moral: false //no
                    },
                607:
                    {
                        id: '607',
                        description: 'Régimen de Enajenación o Adquisición de Bienes',
                        fisica: true, //si
                        moral: false //no
                    },
                608:
                    {
                        id: '608',
                        description: 'Demás ingresos',
                        fisica: true, //si
                        moral: false //no
                    },
                610:
                    {
                        id: '610',
                        description: 'Demás ingresos',
                        fisica: true, //si
                        moral: true //si
                    },
                611:
                    {
                        id: '611',
                        description: 'Ingresos por Dividendos (socios y accionistas)',
                        fisica: true, //si
                        moral: false //no
                    },
                612:
                    {
                        id: '612',
                        description: 'Personas Físicas con Actividades Empresariales y Profesionales',
                        fisica: true, //si
                        moral: false //no
                    },
                614:
                    {
                        id: '614',
                        description: 'Ingresos por intereses',
                        fisica: true, //si
                        moral: false //no
                    },
                615:
                    {
                        id: '615',
                        description: 'Régimen de los ingresos por obtención de premios',
                        fisica: true, //si
                        moral: false //no
                    },
                616:
                    {
                        id: '616',
                        description: 'Sin obligaciones fiscales',
                        fisica: true, //si
                        moral: false //no
                    },
                620:
                    {
                        id: '620',
                        description: 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos',
                        fisica: false, //no
                        moral: true //si
                    },
                621:
                    {
                        id: '621',
                        description: 'Incorporación Fiscal',
                        fisica: true, //si
                        moral: false //no
                    },
                622:
                    {
                        id: '622',
                        description: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras',
                        fisica: false, //no
                        moral: true //si
                    },
                623:
                    {
                        id: '623',
                        description: 'Opcional para Grupos de Sociedades',
                        fisica: false, //no
                        moral: true //si
                    },
                624:
                    {
                        id: '624',
                        description: 'Coordinados',
                        fisica: false, //no
                        moral: true //si
                    },
                625:
                    {
                        id: '625',
                        description: 'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas',
                        fisica: true, //si
                        moral: false //no
                    },
                626:
                    {
                        id: '626',
                        description: '\tRégimen Simplificado de Confianza',
                        fisica: true, //si
                        moral: true //si
                    }
            }

            return rfiscales;
    }
}
export default PopFactura